<template>
  <div class='offline'>
    <h3>离线设备</h3>
    <div class="btn">
      <el-button class="btn_info" @click="$router.go(-1)">返回</el-button>
    </div>
    <base-table ref="myTable" :filterList="filterList" :columns="columns" :tableList="tableData" @queryForm="queryForm"></base-table>
    <base-pagination class="basepagination" v-show="total>10" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" :layout="layout" @pagination="getList"></base-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      layout: 'prev, pager, next',
      tableData: [],
      totalList: [],
      options: [],
      value: '',
      total: 10,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        { attrs: { prop: 'id', label: '设备ID', align: 'center', width: '120' } },
        { attrs: { prop: 'siteName', label: '所属单位', align: 'center', minWidth: '200' } },
        { attrs: { prop: 'adminName', label: '负责人姓名', align: 'center' } },
        { attrs: { prop: 'roomName', label: '设备位置', align: 'center' } },
        {
          attrs: { label: '最近一次上报时间', prop: 'lastOfflineTime', align: 'center' }
        }
      ],
      // 默认数据
      defaultList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['locationList']),
    filterList () {
      const arr = [
        {
          name: '设备位置：',
          type: 'cascader',
          options: this.locationList,
          props: { expandTrigger: 'hover', value: 'id', label: 'name' },
          attrs: {
            style: { width: '350px' },
            size: 'small'
          },
          fieldNames: ['siteid', 'roomid']
        }
      ]
      return arr
    }
  },
  methods: {
    // 初始化数据
    init () {
      this.totalList = JSON.parse(this.$Base64.decode(this.$route.query.offlineList))
      this.defaultList = JSON.parse(this.$Base64.decode(this.$route.query.offlineList))
      this.total = this.totalList.length
      this.getList()
    },
    getList () {
      this.tableData = []
      var start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      var end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }

      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
    },
    queryForm (val) {
      // console.log(val)
      if (val.roomid) {
        this.totalList = this.defaultList.filter(el => el.roomId === val.roomid)
      } else {
        this.totalList = this.defaultList
      }
      this.queryParams.pageSize = 10
      this.queryParams.pageNum = 1
      this.total = this.totalList.length
      this.getList()
    }
  }
};
</script>

<style scoped lang="scss">
.offline {
  margin: 30px 0 30px 30px;
  padding: 20px 20px 10px;
  height: 78vh;
  background: url("../../assets/body.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  h3 {
    color: #30c6fe;
    font-size: 20px;
    font-weight: bold;
  }
  .btn {
    position: absolute;
    right: 20px;
  }
  ::v-deep .filter-box {
    margin: 0;
  }
  ::v-deep .filter-wrapper {
    background: none;
    padding: 0;
    .el-form-item__label {
      color: #fff;
    }
  }
  ::v-deep .el-table {
    background: none;
  }
  ::v-deep .table-wrapper {
    background: none;
    height: 62vh;
    padding: 0;
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: #174e92;
    }
  }
  .basepagination {
    text-align: right;
  }
}
</style>
